<template>
  <div class="pix-container px-2 py-3">
    <div
      class="py-3 d-flex align-center justify-content-center align-items-center"
    >
      <img
        src="@/assets/logo.png"
        style="max-width: 30px"
        class="mr-3"
        alt="Logo Saúde Social"
      />
      <h1 class="mb-0 page-title">Saúde Social</h1>
    </div>
    <hr class="divider" />

    <!-- Error message -->
    <div
      v-if="erroGeral"
      class="my-4"
    >
      <div class="alert alert-danger py-4">
        <h4 class="alert-heading">Erro ao consultar PIX</h4>
        <p class="mb-0 mt-2">
          Ocorreu um erro ao consultar o PIX. Tente novamente mais tarde.
        </p>
      </div>
    </div>

    <!-- Loading state -->
    <div
      v-if="atualizandoQR && qrCodeGerado && !qrCodeGerado.pixCode"
      class="text-center my-4"
    >
      <b-spinner size="lg"></b-spinner>
      <p class="mt-3 loading-text">Carregando seu código de pagamento...</p>
      <div>
        <b-skeleton
          class="mt-3"
          height="30px"
          v-for="n in 5"
          :key="n"
        ></b-skeleton>
      </div>
    </div>

    <!-- QR Code and payment information -->
    <div
      v-if="
        !erroGeral && qrCodeGerado && qrCodeGerado.id && qrCodeGerado.pixCode
      "
      class="payment-content my-3"
    >
      <!-- Paid status -->
      <div
        v-if="qrCodeGerado.status == 'PAGO'"
        class="text-center status-message mb-4"
      >
        <div
          class="status-icon"
          style="font-size: 130px"
        >
          <b-icon
            variant="success"
            icon="check-circle"
            style="font-size: 130px"
          ></b-icon>
        </div>
        <h3 class="mt-3 mb-2">Pagamento confirmado!</h3>
        <p class="status-description">
          Seu pagamento foi confirmado com sucesso. Obrigado por utilizar o PIX.
        </p>
      </div>

      <!-- Expired status -->
      <div
        v-if="qrCodeGerado.status == 'EXPIRADO'"
        class="text-center status-message mb-4"
      >
        <div
          class="status-icon"
          style="font-size: 130px"
        >
          <b-icon
            variant="danger"
            icon="x-circle"
            style="font-size: 130px"
          ></b-icon>
        </div>
        <h3 class="mt-3 mb-2">Código para pagamento expirado</h3>
        <p class="status-description">
          O seu código expirou. Mas não se preocupe! Solicite um novo código para o atendente.
        </p>
      </div>

      <!-- Refunded status -->
      <div
        v-if="qrCodeGerado.status == 'DEVOLVIDO'"
        class="my-4 mx-2 text-center status-message"
      >
        <div class="status-icon">
          <b-icon
            variant="danger"
            icon="backspace"
            style="font-size: 130px"
          ></b-icon>
        </div>
        <h3 class="mt-3 mb-2">Pagamento devolvido</h3>
        <p class="status-description">O pagamento foi devolvido com sucesso!</p>

        <div
          v-if="
            qrCodeGerado.devolucoes &&
            qrCodeGerado.devolucoes.length > 0 &&
            qrCodeGerado.devolucoes[0].rtrId
          "
          class="card card-body w-100 text-left p-4 mt-3"
        >
          <div class="refund-info">
            <div class="mb-3">
              <b>Código Devolução</b><br />
              <span class="refund-data">{{
                qrCodeGerado.devolucoes && qrCodeGerado.devolucoes[0].rtrId
              }}</span>
            </div>
            <div class="mb-3">
              <b>Valor Devolvido</b><br />
              <span class="refund-data">{{
                qrCodeGerado.devolucoes &&
                qrCodeGerado.devolucoes[0].valor | currencyWithMask
              }}</span>
            </div>
            <div class="mb-3">
              <b>Código da Transação</b><br />
              <span class="refund-data">{{ qrCodeGerado.txId }}</span>
            </div>
            <div>
              <b>Data/Hora Devolução</b><br />
              <span class="refund-data">{{
                qrCodeGerado.devolucoes[0].horario.liquidacao
                  | moment("DD/MM/YYYY HH:mm:ss")
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- Expiration timer -->
      <div
        v-if="
          qrCodeGerado &&
          qrCodeGerado.status == 'ATIVA' &&
          pixTempoExpirar &&
          pixTempoExpirar.indexOf('-') < 0
        "
        class="expiration-timer mb-2 text-center"
      >
        <div class="d-flex justify-content-center align-items-center">
          <b-icon
            icon="clock"
            class="mr-2"
          ></b-icon>
          <span
            >Tempo restante: <strong>{{ pixTempoExpirar }}</strong></span
          >
          <!-- <div
            v-if="atualizandoQR"
            class="ml-2"
          >
            <b-spinner
              small
              size="sm"
            ></b-spinner>
          </div> -->
        </div>
      </div>

      <!-- Payment information card -->
      <div class="card payment-info-card mx-2 mb-4">
        <div class="px-4 py-4">
          <div class="payment-details mb-4">
            <div class="detail-row">
              <div class="detail-label">Status:</div>
              <div
                class="detail-value"
                :class="{ 'text-success': qrCodeGerado.status === 'PAGO' }"
              >
                {{
                  qrCodeGerado.status === "PAGO"
                    ? "PAGO"
                    : qrCodeGerado.status === "ATIVA"
                    ? "AGUARDANDO PAGAMENTO"
                    : qrCodeGerado.status === "EXPIRADO"
                    ? "EXPIRADO"
                    : qrCodeGerado.status === "DEVOLVIDO"
                    ? "DEVOLVIDO"
                    : qrCodeGerado.status
                }}
              </div>
            </div>
            <div class="detail-row">
              <div class="detail-label">Valor:</div>
              <div class="detail-value value-highlight">
                {{ qrCodeGerado.valor | currencyWithMask }}
              </div>
            </div>
            <div class="detail-row">
              <div class="detail-label">Pagador:</div>
              <div class="detail-value">{{ qrCodeGerado.devedor_nome }}</div>
            </div>
          </div>

          <!-- Copy PIX code section -->
          <div v-if="qrCodeGerado.status == 'ATIVA'">
            <p class="instruction-text text-center mb-3">
              Ou copie o código PIX abaixo e cole no seu aplicativo bancário
            </p>
            <div
              class="pix-code-container py-3 px-3 mb-3"
              @click="copiarCodigo(qrCodeGerado.pixCode)"
            >
              <div class="pix-code-text">{{ qrCodeGerado.pixCode }}</div>
              <div class="tap-hint text-center mt-2">
                <small
                  ><b-icon
                    icon="hand-index"
                    class="mr-1"
                  ></b-icon>
                  Toque para copiar</small
                >
              </div>
            </div>
            <b-button
              size="lg"
              variant="primary"
              block
              class="copy-button py-3"
              @click="copiarCodigo(qrCodeGerado.pixCode)"
            >
              <b-icon
                icon="clipboard"
                class="mr-2"
              ></b-icon>
              COPIAR CÓDIGO PIX
            </b-button>
          </div>
        </div>
      </div>

      <!-- QR Code section - Repositioned to appear first for active payments -->
      <div
        v-if="qrCodeGerado.status == 'ATIVA'"
        class="text-center mb-4"
      >
        <div class="instruction-text mb-3">
          <h4>- OU -</h4>
          <div>
            Você pode escanear o QRCODE abaixo com o aplicativo do seu banco
            utilizando a câmera de outro dispositivo.
          </div>
        </div>

        <img
          v-if="qrCodeGerado && qrCodeGerado.pixQrCode"
          :src="qrCodeGerado.pixQrCode"
          class="qr-code-img"
          alt="QR Code para pagamento PIX"
        />
      </div>
    </div>

    <!-- Instructions Modal -->
    <b-modal
      v-model="mostrarInstrucoes"
      id="modal-instrucoes"
      title="Como realizar o pagamento"
      centered
      hide-footer
      body-class="p-4"
      header-class="modal-header2"
      header-bg-variant="primary"
      header-text-variant="light"
      content-class="instructions-modal-content"
    >
      <template #modal-header="{ close }">
        <div class="d-flex w-100 align-items-center">
          <h5 class="modal-title">
            <b-icon
              icon="info-circle"
              class="mr-2"
            ></b-icon>
            Como realizar o pagamento
          </h5>
          <button
            type="button"
            class="close ml-auto"
            @click="close()"
            aria-label="Fechar"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </template>
      <div class="modal-body">
        <h4 class="mb-3">
          <b-icon
            icon="info-circle"
            class="mr-2"
          ></b-icon>
          Instruções
        </h4>
        <ul class="instructions-list pl-3">
          <li class="mb-2">Clique no botão vermelho (COPIAR CODIGO PIX)</li>
          <li class="mb-2">
            Acesse o aplicativo do seu banco na opção PIX COPIE E COLA
          </li>
          <li class="mb-2">Cole o código e confirme o pagamento</li>
          <li class="mb-2">Aguarde a confirmação do pagamento no sistema</li>
        </ul>
        <div class="text-center mt-4">
          <b-button
            variant="primary"
            @click="mostrarInstrucoes = false"
            size="lg"
            >Entendi</b-button
          >
        </div>
      </div>
    </b-modal>

    <!-- Floating help button -->
    <div
      v-if="qrCodeGerado.status == 'ATIVA'"
      class="floating-help-button"
      @click="toggleInstructions"
      aria-label="Mostrar instruções de pagamento"
      title="Clique para ver instruções de pagamento"
    >
      <b-icon
        icon="question-circle"
        scale="1.5"
      ></b-icon>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import CaixasLib from "@/libs/CaixasLib";
export default {
  components: {},
  props: {},
  mounted() {
    this.id = atob(this.$route.params.id);
    if (this.id) {
      this.qrCodeGerado = {
        id: this.id,
      };
      this.atualizarPIX();
    }
  },
  data() {
    return {
      id: "",
      qrCodeGerado: {},
      atualizandoQR: false,
      qrCodeIdentificador: "",
      pixErrorMessage: "",
      podeCancelarPix: "",
      pixTempoExpirar: "",
      timerTempoPix: null,
      erroGeral: false,
      loading: false,
      timerAtualizaPix: null,
      mostrarInstrucoes: false,
    };
  },
  watch: {
    qrCodeGerado: function () {
      if (this.timerAtualizaPix) {
        clearInterval(this.timerAtualizaPix);
      }
      this.timerAtualizaPix = null;
      //   this.pixTempoExpirar = "";

      if (
        this.qrCodeGerado &&
        this.qrCodeGerado.status &&
        this.qrCodeGerado.status === "ATIVA"
      ) {
        this.timerAtualizaPix = setTimeout(() => {
          this.atualizarPIX();
        }, 2000);
      }
    },
  },
  computed: {},
  methods: {
    toggleInstructions() {
      this.mostrarInstrucoes = !this.mostrarInstrucoes;
    },
    copiarCodigo(code) {
      const msgSucces = () => {
        this.$swal({
          title: "Código copiado com sucesso!",
          // text: "Agora você pode colar no aplicativo do seu banco",
          icon: "success",
          size: "lg",
          toast: true,
          position: "center",
          showConfirmButton: false,
          timer: 3000,
          timeout: 3000,
        });
      };
      const msgError = () => {
        this.$swal({
          title: "Erro ao copiar código!",
          text: "Tente novamente ou copie manualmente",
          icon: "error",
          toast: true,
          position: "center",
          showConfirmButton: false,
          timer: 3000,
          timeout: 3000,
        });
      };
      if (!navigator.clipboard) {
        // Fallback for browsers that do not support the Clipboard API
        let textArea = document.createElement("textarea");
        textArea.value = code;
        textArea.style.position = "fixed"; // Avoid scrolling to bottom
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          document.execCommand("copy");
          msgSucces();
        } catch (err) {
          console.error("Fallback: Oops, unable to copy", err);
          msgError();
        }
        document.body.removeChild(textArea);
      } else {
        navigator.clipboard
          .writeText(code)
          .then(() => {
            msgSucces();
          })
          .catch((err) => {
            console.error("Async: Could not copy text: ", err);
            msgError();
          });
      }
    },
    async atualizarPIX() {
      console.log(333);
      this.pixErrorMessage = "";
      this.erroGeral = false;

      try {
        if (this.qrCodeGerado && this.qrCodeGerado.id) {
          console.log(123);
          let id = this.qrCodeGerado.id;
          this.atualizandoQR = true;
          try {
            let res = await CaixasLib.consultarPix(this.qrCodeGerado.id);

            if (res) {
              this.qrCodeGerado = res;
            }
            if (typeof this.qrCodeGerado === "undefined") {
              //setTimeout(() => this.atualizarPIX(), 100);
              this.pixErrorMessage = "Conexão instável. Tente novamente.";
              this.qrCodeIdentificador = id;
            } else {
              console.log("result", this.qrCodeGerado);
              this.podeCancelarPix =
                this.qrCodeGerado.status && this.qrCodeGerado.status == "PAGO";

              if (
                this.qrCodeGerado.success === false ||
                (this.qrCodeGerado && !this.qrCodeGerado.pixCode)
              ) {
                // this.qrCodeGerado = null;
                this.pixErrorMessage = "Identificador não encontrado";
              }
              if (
                this.qrCodeGerado &&
                this.qrCodeGerado.venda &&
                this.qrCodeGerado.venda.venda_id
              ) {
                this.pixErrorMessage = `PIX JÁ UTILIZADO NA VENDA CODIGO ${this.qrCodeGerado.venda.venda_id}`;
                // this.qrCodeGerado = null;
              }

              if (this.qrCodeGerado) {
                let tempoMinutos = moment(this.qrCodeGerado.processAt)
                  .add(this.qrCodeGerado.expiracao, "s")
                  .diff(moment(), "minutes");
                let tempoSegundos = parseInt(
                  moment(this.qrCodeGerado.processAt)
                    .add(this.qrCodeGerado.expiracao, "s")
                    .diff(moment(), "seconds") % 60
                );
                console.log("tempoMinutos", tempoMinutos);
                this.pixTempoExpirar =
                  (tempoMinutos < 10 ? "0" : "") +
                  tempoMinutos +
                  ":" +
                  (tempoSegundos < 10 ? "0" : "") +
                  tempoSegundos;
                console.log(
                  (tempoMinutos < 10 ? "0" : "") +
                    tempoMinutos +
                    ":" +
                    (tempoSegundos < 10 ? "0" : "") +
                    tempoSegundos
                );
              }
            }
          } catch (error) {
            console.log("ERROR ON GERAR PIX", error);
          }
          this.atualizandoQR = false;
        }
      } catch (e) {
        console.log(e);
        this.erroGeral = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
html,
body {
  height: 100%;
  // height: auto !important;
  overflow-x: hidden;
}
#app {
  // height: auto !important;
}
.pix-container {
  max-width: 500px;
  margin: 0 auto;
  background-color: #ffffff;
  height: 100%;
  min-height: 100%;
  position: relative;
  padding-bottom: 20px;
  overflow-y: auto;
}

.payment-content {
  overflow-y: visible;
}

.page-title {
  font-size: 1.8rem;
  font-weight: 600;
  color: #2c3e50;
}

.divider {
  margin: 0.5rem 0 1rem 0;
  border-top: 2px solid #eaeaea;
}

.instruction-text {
  font-size: 1.1rem;
  color: #555;
  margin-bottom: 1rem;
}

.qr-code-img {
  max-width: 100%;
  height: auto;
  border: 2px solid #eaeaea;
  border-radius: 8px;
  padding: 5px;
  margin: 0 auto;
}

.loading-text {
  font-size: 1.1rem;
  color: #666;
}

.status-message {
  padding: 1rem;
}

.status-icon {
  margin: 1rem 0;
}

.status-description {
  font-size: 1.1rem;
  color: #555;
  margin-top: 0.5rem;
}

.expiration-timer {
  font-size: 1.1rem;
  color: #e74c3c;
  padding: 0.5rem 0;
}

.payment-info-card {
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border: none;
}

.payment-details {
  font-size: 1.1rem;
}

.detail-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #f0f0f0;
}

.detail-row:last-child {
  border-bottom: none;
}

.detail-label {
  font-weight: 600;
  color: #555;
}

.detail-value {
  font-weight: 500;
  text-align: right;
}

.value-highlight {
  font-size: 1.3rem;
  font-weight: 700;
  color: #2c3e50;
}

.pix-code-container {
  background-color: #f8f9fa;
  border-radius: 10px;
  border: 2px dashed #007bff;
  cursor: pointer;
  transition: all 0.2s ease;
}

.pix-code-container:active {
  background-color: #e9ecef;
  transform: scale(0.98);
}

.pix-code-text {
  font-size: 1rem;
  word-break: break-all;
  text-align: center;
  font-family: monospace;
}

.tap-hint {
  color: #6c757d;
}

.copy-button {
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 10px;
  transition: all 0.2s ease;
}

.copy-button:active {
  transform: scale(0.98);
}

.refund-info {
  font-size: 1.1rem;
}

.refund-data {
  font-weight: 500;
  color: #2c3e50;
}

/* Accessibility improvements */
button:focus,
div:focus {
  outline: 3px solid #007bff !important;
}

@media (max-width: 576px) {
  .pix-container {
    padding: 1rem;
    padding-bottom: 30px;
  }

  .payment-details {
    font-size: 1rem;
  }

  .instruction-text {
    font-size: 1rem;
  }
}

.instructions-container {
  margin-bottom: 2rem;
  text-align: left;
}

.instructions-list {
  font-size: 1.1rem;
  color: #555;
  padding-left: 1.5rem;
}

.instructions-list li {
  padding-left: 0.5rem;
  line-height: 1.5;
}

.floating-help-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #007bff;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  z-index: 1000;
  transition: all 0.2s ease;
}

.floating-help-button:hover {
  transform: scale(1.05);
  background-color: #0069d9;
}

.floating-help-button:active {
  transform: scale(0.95);
}

/* Modal styling enhancements */
.instructions-modal-content {
  border-radius: 12px;
  overflow: hidden;
}

.modal-body {
  padding: 1.5rem;
}

.modal-body .instructions-list {
  font-size: 1.15rem;
}

.modal-title {
  font-weight: 600;
  margin-bottom: 0;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

/* Animation for the modal */
.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.3s ease;
}

.modal-enter,
.modal-leave-to {
  opacity: 0;
}

@media (max-width: 576px) {
  .floating-help-button {
    bottom: 15px;
    right: 15px;
    width: 45px;
    height: 45px;
  }
}

.modal-header2 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid rgba(60, 60, 60, 0.26);
  border-top-left-radius: calc(2rem - 1px) !important;
  border-top-right-radius: calc(2rem - 1px) !important;
}
</style>
